.link {
  color: var(--abb-grey-1);
  white-space: nowrap;
  transition: ease-in-out 0.3s;
  text-decoration: none;
  &:hover {
    color: var(--abb-red);
  }
}
.copyIcon {
  margin-right: 5px;
  cursor: pointer;
}
