.process {
  &Box {
    margin-bottom: 35px;
    border: 2px solid var(--abb-grey-11);
    padding: 15px;
    border-radius: 8px;
    &Inner {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 20px;
    }
  }
  &Header {
    margin-bottom: 25px;
    color: var(--abb-red);
    text-align: center;
  }
}
.unitWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.farButtonsWrapper{
  display: flex;
  gap: 20px;
}