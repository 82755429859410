.columnBreakText {
    max-width: 100%;
    word-break: break-word;
}
.errorMessage {
    margin-bottom: 10px;
}

.disclaimer {
    font-weight: bold;
    color: var(--abb-red);
    text-align: center;
    margin-top: 20px;
    font-style: italic ;
  }

  .formWrapper {
    width: 100%;
    margin: 0 auto;
  }
