.commands{
    display: flex;
    align-items: center;
    gap: 20px;
}
.wrapper {
    display: grid;
    grid-template-columns: 150px 100px 1fr;
    gap: 20px;
    > * {
        margin: 0;
    }
}
.inputWrapper{
    max-width: 100%;
    padding-right: 10px;
    overflow: auto;
}