$bgColor: var(--abb-grey-5);
$bgColorActive: var(--abb-grey-4);
$fontColor: var(--abb-grey-1);

.wrapper{
  max-width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.hoverableListItem{
  position: relative;
  width: 250px;
  max-width: 100%;
  .wrapper > & {
    width: auto;
  }
}

.listItemWrapper{
  display: flex;
  justify-content: space-between;
}

.childrenWrapper{
  position: absolute;
  top: 0;
  left: 100%;
  margin: 0;
  padding: 0;
  background-color: $bgColor;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  list-style: none;
  line-height: normal;
  &.initialSubdropdown{
    top: 100% !important;
    left: 0;
  }
  .hoverableListItem > & {
    top: -10000vh;
  }
  .hoverableListItem.hasBeenHovered > & {
    top: 0;
  }
  .hoverableListItem.hover > & {
    pointer-events: all;
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(1, 0, 1, 0.25);
    transition-duration: 0.75s;
  }
  .hoverableListItem.hover:hover > & {
    opacity: 1;
    transition-duration: 0s;
  }
}

.link {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 10px;
  color: $fontColor;
  font-size: var(--abb-default-font-size);
  white-space: nowrap;
  text-decoration: none;
  .hoverableListItem:hover > .listItemWrapper & {
    background-color: $bgColorActive;
  }
  &.nonProcess{
    cursor: default;
    font-style: italic;
  }
}

.icon {
  margin-left: 10px;
}

.title {
  display: flex;
  align-items: center;
  padding-right: 5px;
  font-size: var(--abb-default-font-size);
  white-space: break-spaces;
}
.downButton{
  padding: 10px;
  color: $fontColor;
  font-size: var(--abb-default-font-size);
  align-self: stretch;
  &:hover,
  .hoverableListItem:hover > .listItemWrapper > &{
    background-color: $bgColorActive;
  }
}
