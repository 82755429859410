.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-top: 10px;
  > div {
    min-width: 150px;
    flex-basis: calc(15% - 50px);
    flex-grow: 1;
  }
  .button {
    display: flex;
    min-width: 100px;
    flex-direction: column;
    flex-basis: 100px;
    align-self: end;
  }
  .filtersLabel {
    font-weight: 600;
  }
}
.dropdownWithTooltip {
  display: flex;
  .tooltip {
    position: relative;
    top: 25%;
  }
  .dropdown {
    background-color: red !important;
    width: 94%;
  }
}

// .dropdown {
//   padding-top: 10px;
// }
.fitPlaceholder {
  max-width: fit-content;
}
.label {
  font-size: var(--abb-default-font-size);
}
.toggleGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  .toggle {
    padding-top: 10px;
    padding-left: 10px;
  }
}
