.wrapper {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.dropdown {
  width: 300px;
  max-width: calc(100% - 32px);
}
.remove {
  color: var(--abb-white);
  &:hover {
    color: var(--abb-red);
  }
}

.supportingNodes {
  width: calc(100% - 25px);
  padding-left: 120px;
}

.supportingNode,
.mainNode {
  display: flex;
  height: 53px;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  padding: 10px;
  font-size: 14px;
  text-decoration: none;
  line-height: 1.4;
}

.supportingNode {
  color: inherit;
  background-color: var(--abb-grey-5);
  text-align: center;
  &.link:hover {
    background: var(--abb-grey-4);
  }
}

.mainWrapper {
  position: relative;
  display: flex;
  width: calc(100% - 25px);
}
.mainNodes {
  position: relative;
  display: grid;
  flex-grow: 1;
  gap: 10px;
}
.mainNode {
  position: relative;
  min-width: 150px;
  height: 60px;
  padding-right: 0px;
  padding-left: 27px;
  color: var(--abb-white);
  background-color: var(--abb-red);
  &::after {
    position: absolute;
    display: block;
    top: 0;
    right: -24.5px;
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 25px solid var(--abb-red);
    z-index: 1;
    content: ' ';
  }
  &.link {
    &:hover {
      background-color: var(--abb-red-2);
      &::after {
        border-left-color: var(--abb-red-2);
      }
    }
  }
}

.column .mainNode::before {
  position: absolute;
  display: block;
  top: 0;
  left: 0px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 25px solid var(--abb-white);
  content: ' ';
}
.column:first-of-type .mainNode::before {
  display: none;
}

.child {
  display: flex;
  height: 60px;
  align-items: center;
  margin-bottom: 8px;
  padding: 5px 10px;
  color: inherit;
  background-color: var(--abb-grey-5);
  text-decoration: none;
  &.link {
    &:hover {
      background-color: var(--abb-grey-4);
    }
  }
}

.longArrow {
  position: absolute;
  left: 80px;
  width: 90px;
  height: 60px;
  background: var(--abb-red);
}

.leftWrapper {
  position: relative;
  min-width: 170px;
  width: 170px;
  margin-top: -10px;
  border-top: 10px solid var(--abb-white);
  border-right: 10px solid var(--abb-white);
  border-top-right-radius: 90px;
}

.boxedCircle {
  position: relative;
  width: 100%;
  height: 160px;
  margin-bottom: 10px;
  border: 15px solid var(--abb-red);
  background: var(--abb-grey-6);
  border-radius: 500px;
}

.img {
  position: relative;
  margin-top: 0px;
  margin-left: 20px;
  z-index: 999;
  img {
    position: relative;
    width: 100px;
    height: 100px;
    margin-top: 13px;
  }
}

.arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  box-sizing: content-box;
  border-top: 4px solid var(--abb-grey-6);
  border-left: 4px solid var(--abb-grey-6);
  background: transparent;
}

.arrow0 {
  top: 59%;
  width: 15px;
  height: 15px;
  margin-left: -13px;
  transform: rotate(31deg);
}
.arrow1 {
  top: -15%;
  margin-left: 55px;
  transform: rotate(141deg);
}
.arrow2 {
  top: 52%;
  margin-left: 124px;
  transform: rotate(-121deg);
}
.arrowBottom {
  position: absolute;
  top: 134px;
  width: 20px;
  height: 20px;
  margin-left: 56px;
  background: var(--abb-red);
  transform: rotate(45deg);
}
.text {
  color: var(--abb-red);
  font-size: 16px;
  text-align: center;
  font-weight: lighter;
}

.deleted {
  text-decoration: line-through;
}
