.main {
  padding-top: 55px;
  & &Wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}
footer {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 25px;
}

.link {
  color: rgb(96, 94, 92);
  font-family: 'ABBvoice Lt';
  max-width: 100%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  &:hover {
    color: var(--abb-red);
    transition: all ease-in-out 0.3s;
  }
}

.acknowledgement {
  &Details {
    margin-bottom: 25px;
    font-family: 'ABBvoice Lt';
    h6 {
      margin-bottom: 8px;
      font-size: 18px;
    }
    p,
    div {
      font-weight: 500;
      margin-bottom: 15px;
      font-family: 'ABBvoice Lt';
      a {
        color: rgb(96, 94, 92);
        font-weight: 500;
        text-decoration: none;
        transition: all ease-in-out 0.3s;
        &:hover {
          color: var(--abb-red);
          transition: all ease-in-out 0.3s;
        }
      }
    }
  }

  &Explanation {
    margin-bottom: 25px;
    color: var(--abb-red);
    font-size: 24px;
  }
}
