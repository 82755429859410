.toggle{
    margin-top: 6px;
}

.removed::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 4px;
    height: 45px;
    content: "";
}

.removed::before{
    background: red;
}