:root {
  --abb-default-font-size: 13px;

  --abb-red: #ff000f;
  --abb-red-2: #bb2924;
  --abb-red-3: #ee000f;
  --abb-red-light-5: rgba(255,0,15,0.05);
  --abb-red-light-25: rgba(255,0,15,0.25);
  --abb-red-light-50: rgba(255,0,15,0.5);
  --abb-black: #000;
  --abb-black-2: #0f0f0f;
  --abb-white: #fff;
  --abb-white-light-30: rgba(255,255,255,0.3);
  --abb-white-2: rgba(255,255,255,.85);
  --abb-grey-1: #262626;
  --abb-grey-2: #6e6e6e;
  --abb-grey-3: #a9a9a9;
  --abb-grey-4: #d2d2d2;
  --abb-grey-5: #f0f0f0;
  --abb-grey-6: #fafafa;
  --abb-grey-7: #979797;
  --abb-grey-8: #8b8686;
  --abb-grey-9: #464646;
  --abb-grey-10: #ebebeb;
  --abb-grey-11: #dbdbdb;
  --abb-grey-12: #696969;
  --abb-theme-neutralSecondary: #605e5c;

  --palette-primary-main: var(--abb-red);
  --palette-primary-light-5: var(--abb-red-light-5);
  --palette-primary-light-25: var(--abb-red-light-25);
  --palette-primary-light-50: var(--abb-red-light-50);
  --palette-primary-2: var(--abb-red-2);
  --palette-primary-3: var(--abb-red-3);
  --palette-black: var(--abb-black);
  --palette-black-2: var(--abb-black-2);
  --palette-white: var(--abb-white);
  --palette-white-light-30: var(--abb-white-light-30);
  --palette-white-2: var(--abb-white-2);
  --palette-grey-1: var(--abb-grey-1);
  --palette-grey-2: var(--abb-grey-2);
  --palette-grey-3: var(--abb-grey-3);
  --palette-grey-4: var(--abb-grey-4);
  --palette-grey-5: var(--abb-grey-5);
  --palette-grey-6: var(--abb-grey-6);
  --palette-grey-7: var(--abb-grey-7);
  --palette-grey-8: var(--abb-grey-8);
  --palette-grey-9: var(--abb-grey-9);
  --palette-grey-10: var(--abb-grey-10);
  --palette-grey-11: var(--abb-grey-11);
  --palette-grey-12: var(--abb-grey-12);
  --page-title-color: inherit;
  --paragraph-h2-color: var(--abb-black-2);
  --paragraph-pre-color: var(--abb-grey-9);
  --statement-h2-color: var(--abb-black-2);
  --content-tabs-heading: var(--abb-grey-12);
}