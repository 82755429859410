.footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 20px 0;
  .buttonsAction button {
    margin-right: 15px;
  }
}


.formWrapper {
  width: 850px;
  max-width: 850px;
  margin: 0 auto;
}

.labelWrapper{
  display: flex;
  i {
    margin-left: 0;
  }
}