.tab {
  margin-top: 10px;
}
.buttons {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}
.deleteButtons {
  display: flex;
  margin: 20px 0;
  gap: 20px;
}
