.box {
  margin: 25px 0;
  &Top {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  &Bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    gap: 15px;
  }
  &Icon {
    font-size: 38px;
    cursor: pointer;
    align-self: flex-end;
    transition: all ease-in-out 0.3s;
    &:hover {
      color: var(--abb-red);
      transition: all ease-in-out 0.3s;
    }
  }
}
.tooltipWrapper{
  display: flex;
  justify-content: space-between;
}