.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.textField, .dropdown {
  width: 20%;
  margin-right: 15px;
}
.label {
  margin-top: 15px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
