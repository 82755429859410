.button {
  margin: 10px 0;
}

.thumbs {
  display: flex;
  margin: 5px 0;
  gap: 10px;
}

.date {
  margin: 5px 0;
}

.delete {
  width: 170px;
}

.repliesMarginLeft {
  padding-left: 20px;
}

.mainComment.sunEditor,
.comment.sunEditor {
  margin-top: 3px;
  width: 100%;
  border: 1px solid #dadada;
  color: rgb(15, 15, 15);
  background-color: transparent;
  font-size: 16px;
  font-family: ABBvoice;
  border-radius: 5px;
}
.mainComment.sunEditor {
  background-color: var(--palette-grey-5);
}

.arrow {
  transform: rotate(180deg);
  margin-right: 5px;
}

.commentWithArrow {
  display: flex;
  align-items: flex-start;
}

.wrapper {
  display: flex;
  margin-bottom: 20px;
}

.leftWrapper {
  flex-basis: 200px;
}

.commentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttonContainer {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.replyTo {
  display: block;
  border: 1px solid #dadada;
  background: none;
  padding: 6px 16px 0px 16px !important;
  align-content: center !important;
  align-items: center;
  background-color: var(--palette-grey-5);
  border-radius: 5px;
  margin-left: 7% !important;
  color: (15, 15, 15);
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 5px;
}

.richTextContainer {
  margin-top: 15px;
}

.feedbackDisclaimer {
  margin-bottom: 15px;
  border-left: 3px solid var(--abb-red);
  padding: 5px;
  font-weight: bolder;
}

.grayOut {
  opacity: 0.5;
}
