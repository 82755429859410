
.link, .nonlink {
  display: block;
  color: rgb(96, 94, 92);
  font-family: 'ABBvoice Lt';
}
.link {
  max-width: 100%;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  &:hover {
    color: var(--abb-red);
    transition: all ease-in-out 0.3s;
  }
}


.pdcInstead {
  margin-top: 20px;
}