.displayGrid {
  display: grid;
}
.displayGridCell {
  display: flex;
  padding: 10px;
}
.displayGridToggle {
  width: 25px;
}
.isHeader {
  position: sticky;
  top: 0;
  background: white;
  font-family: ABBvoice Md;
  z-index: 1;
}
.firstTitle {
  padding-left: 35px;
}
.isChild {
  padding-left: 25px;
  .displayTableCell.displayTableCellTitle {
    width: 670px;
  }
  & .isChild {
    .displayTableCell.displayTableCellTitle {
      width: 645px;
    }
    & .isChild {
      .displayTableCell.displayTableCellTitle {
        width: 620px;
      }
      & .isChild {
        .displayTableCell.displayTableCellTitle {
          width: 595px;
        }
        & .isChild {
          .displayTableCell.displayTableCellTitle {
            width: 570px;
          }
          & .isChild {
            .displayTableCell.displayTableCellTitle {
              width: 545px;
            }
            & .isChild {
              .displayTableCell.displayTableCellTitle {
                width: 520px;
              }
              & .isChild {
                .displayTableCell.displayTableCellTitle {
                  width: 495px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.tooltipIcon {
  margin-left: 5px;
}
