$colorHover: var(--abb-grey-10);
$colorBasic: var(--abb-grey-4);
$colorFont: var(--abb-grey-1);
$colorBorder: var(--abb-grey-11);

.box {
  border: 2px solid $colorBorder;
  border-radius: 8px;
  margin-bottom: 35px;
  padding: 15px;

  &Inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
    flex-wrap: wrap;
  }
}
.tile {
  align-self: stretch;
  background-color: $colorBasic;
  border-radius: 8px;
  border: 2px solid $colorBorder;
  color: inherit;
  display: flex;
  font-size: var(--abb-default-font-size);
  padding: 10px 10px;
  text-decoration: none;
  transition: all ease-in-out 0.3s;

  &.nonProcess:hover {
    background-color: $colorBasic;
  }

  &:hover {
    background-color: $colorHover;
  }
}
.header {
  color: var(--abb-red);
  text-align: center;
  margin-bottom: 25px;
}
