.process {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.description {
  margin-bottom: 15px;
  border-bottom: 1px solid var(--abb-grey-5);
  padding-bottom: 15px;
  h5 {
    font-size: 18px;
    font-weight: 600;
  }
  p {
    line-height: 1.5;
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 20px 0;
  &Left {
    button + .buttonsAction {
      margin-right: 15px;
    }
  }
  &Right {
    button + .buttonsAction {
      margin-left: 15px;
    }
  }
  .buttonsAction > div {
    margin-right: 15px;
  }
}
.buttonsNav {
  display: flex;
  color: var(--abb-grey-9);
  .buttonNavPrev,
  .buttonNavNext {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    font-size: 12px;
    line-height: 2;
    transition: all ease-in-out 0.3s;
    &:hover {
      color: var(--abb-red);
      cursor: pointer;
      transition: all ease-in-out 0.3s;
    }
  }
  .buttonNavPrev i {
    margin-right: 10px;
    font-size: 10px;
    font-weight: 600;
  }
  .buttonNavNext i {
    margin-left: 10px;
    font-size: 10px;
    font-weight: 600;
  }
}
.processOwnerWrapper {
  display: flex;
  justify-content: flex-end;
}
.formWrapper {
  width: 100%;
  margin: 0 auto;
}
.split {
  margin-left: 15px;
}
.split-arrow {
  margin: 0;
}
.processMeta {
  display: flex;
  align-items: center;
  gap: 20px;
}
.processMetaWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}
.processSubWrapper,
.processLinksWrapper {
  margin-top: 15px;
}
.table {
  display: grid;
  margin: 20px 0;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  :global .ms-Toggle {
    margin: 0;
  }
}
.documentsWrapper {
  border-bottom: 1px solid var(--abb-grey-5);
  padding-bottom: 15px;
}
.update, .mirrorcopy {
  display: flex;
  height: 32px;
  align-items: center;
  padding: 0 16px;
  background: var(--abb-grey-4);
  border-radius: 20px;
  gap: 5px;
}

.descriptionWrapper {
  overflow: hidden;
}
.nav {
  display: flex;
  justify-content: space-between;
}
.tab {
  margin-top: 10px;
}
.changes {
  width: 100%;
  max-width: 100%;
  white-space: normal;
}
.disclaimer {
  font-weight: bold;
  color: var(--abb-red);
  text-align: center;
  margin-top: 20px;
  font-style: italic;
}

.acknowledgement {
  .acknowledgementPicker {
    margin-bottom: 20px;
  }
  .acknowledgementBtn {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .comment label {
    font-family: 'ABBvoice Lt';
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    padding: 5px 0px;
    display: block;
  }
}

.toggle {
  margin-top: 15px;
}
