.footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 20px 0;
  .buttonsAction button {
    margin-right: 15px;
  }
}
.narrowInput {
  width: 500px;
}
