.accordion {
  overflow: hidden;
}
.title {
  display: flex;
  align-items: center;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  transition: background 0.5s ease-in-out;
  &:hover {
    background: rgba(255,0,0,0.03);
    cursor: pointer;
  }
  &Text {
    margin-left: 10px;
  }
}

.content {
  height: 0;
  font-size: 14px;
  border-radius: 4px;
  transition: all 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  &Open {
    height: auto;
    padding: 15px 25px;
    transition: all 350ms cubic-bezier(0.08, 1.09, 0.32, 1.275);
  }
  &Text {
    visibility: hidden;
    opacity: 0;
    &Open {
      visibility: visible;
      opacity: 1;
      transition: all 0.8s ease-in;
    }
  }
}

.chevronIcon {
  color: var(--abb-grey-4);
  font-size: 15px;
  transition: transform 0.3s linear 0s;
  transform: rotate(270deg);
  &.isOpen {
    transform: rotate(180deg);
  }
}
