.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 10 0 0%;


  .chatbotIcon {
    background-color: var(--abb-red);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
  }


  a {
    color: var(--abb-grey-1);
    &:hover {
      &::after {
        background: pink;
      }
    }
  }

  .chatbotContainer {
    color: white;
  }

  a, .chatbotContainer {
    position: relative;
    cursor: pointer;
    display: block;
    height: 100%;
    padding: 0 15px;
    font-size: 16px;
    font-family: 'ABBvoice Lt';
    text-decoration: none;
    transition: all ease-in-out 0.3s;
    &::after{
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 4px;
      background: transparent;
      content: ' ';
    }
  
    &.active {
      font-weight: 600;
    }
  }
  .active {
    &::after {
      background: red;
    }
  }
}

a.myUnit {
  cursor: default;
    &:hover {
      &::after {
        background: red;
      }
    }
}