.dropdownUnit {
  display: flex;
  gap: 15px;
  align-items: baseline;
  .onShowUnit {
    align-self: flex-end;
  }
}

.footer {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}
