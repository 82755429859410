.tableWrapper {
  max-width: 100%;
  margin-bottom: 20px;
  overflow-x: auto;
  td {
    min-width: 300px;
  }
}
.link,
.nonlink {
  display: block;
  color: var(--abb-grey-9);
}
.link {
  cursor: pointer;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  &:hover {
    color: var(--abb-red);
    transition: all ease-in-out 0.3s;
  }
}
.deleteButton {
  margin-bottom: 5px;
}
.isEdit {
  td {
    vertical-align: bottom;
  }
}

.deleted {
  text-decoration: line-through;
}

$colorHover: var(--abb-grey-4);
$colorBasic: var(--abb-grey-10);
$colorFont: var(--abb-grey-1);
$colorBorder: var(--abb-grey-11);

.box {
  border: 2px solid $colorBorder;
  border-radius: 8px;
  margin-bottom: 35px;
  width: 100%;
  padding: 15px;
}

.container {
  color: var(--abb-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--abb-red);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .header {
    width: 20%;
    padding: 10px;
    text-align: start;
  }

  .searchBox {
    width: 30%;
    padding: 10px;

    label {
      margin-right: 5px;
    }

    .inputBox {
      width: 90%;
    }
  }
}

.title,
.processTitle {
  width: 40%;
}

.processRevision {
  width: 20%;
}

table.matrixDiagramFront {
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: start;
  }

  th,
  td {
    padding: 12px 15px;
  }

  tr {
    border-bottom: 1px solid $colorBorder;
  }

  tr:nth-of-type(even) {
    background-color: $colorBasic;
  }

  tr:last-of-type {
    border-bottom: 2px solid var(--abb-red);
  }
}
