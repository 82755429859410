.chevronIcon {
  color: var(--abb-grey-4);
  font-size: 15px;
  cursor: pointer;
  transition: transform 0.3s linear 0s;
  transform: rotate(270deg);
  &.isOpen {
    transform: rotate(180deg);
  }
}

.editButton {
  min-width: 30px;
  padding: 0 3px;
}
.inheritStatusCheckboxOuterWrapper {
  display: flex;
  align-items: center;
}
.inheritStatusCheckboxWrapper {
  display: flex;
  align-items: center;
  border: 1px solid #8a8886;
  border-radius: 1px;
  padding: 0 0 0 3px;
  margin-right: 10px;
  .editAdvancedButton {
    width: 30px;
    min-width: 0;
    padding: 0 3px;
    border: none;
  }
}
.inheritStatusCheckboxNoChild {
  margin-right: 20px;
}
