.title {
  display: flex;
  align-items: center;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
}

.process {
  &Box {
    margin-bottom: 35px;
    border: 2px solid var(--abb-grey-11);
    padding: 15px;
    border-radius: 8px;
  }
}


