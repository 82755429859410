%bar{
  display: flex;
  height: 48px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  line-height: 48px;
}

.appName {
  max-height: 24px;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0 14px;
  font-size: 14px;
  font-family: "ABBvoice";
  line-height: 24px;
}

.box {
  position: sticky;
  top: 0;
  width: 100%;
  background: white;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  z-index: 1000000;
  .topbar {
    width: 100%;
    @extend %bar;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    img {
      height: 24px;
    }
  }
  .person {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    span {
      flex-shrink: 0;
      padding: 0 15px 0 5px;
      font-size: 12px;
    }
    .logoutIcon {
      cursor: pointer;
    }
  }
}
.rightIcons{
  @extend %bar;
}
.searchWrapper{
  display: flex;
  max-width: 720px;
  flex-grow: 1;
  align-items: center;
}
.search{
  max-width: 720px;
  flex-grow: 1;
}