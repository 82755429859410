.peoplePicker {
    :global {
        .ms-Suggestions-closeButton{
            display: none !important;
        }
        .ms-PeoplePicker-Persona{
            width: auto;
        }
    }
}
.removedWrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px 0;
    gap: 10px;
}