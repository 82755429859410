.filterWrapper {
    padding: 4px;
}
  
.filterIcon {
    padding-left: 0;
    :global {
    .ms-Button-icon {
    margin-left: 0;
    }
    .ms-Button-menuIcon {
    margin-right: 0;
    }
}
}