.container {
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #dadada;
  color: rgb(15, 15, 15);
  background-color: transparent;
  font-size: 13px;
  font-family: ABBvoice;
  border-radius: 5px;
  background-color: var(--palette-grey-5);
  padding: 5px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 20px;
}