$backgroundChild: var(--abb-grey-5);

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}

.selectAllBtn {
  margin-top: 20px;
}

.UnitAssignmentStripped {
  &:nth-child(4n + 3),
  &:nth-child(4n) {
    background-color: #f7f7f7;
  }
}

.tab {
  margin-top: 10px;
}

.buttons{
  display: flex;
  margin: 20px 0;
  gap: 20px;
}
