@mixin splitButton() {
  :global {
    .ms-Button {
      border: 1px solid var(--abb-red);
      padding-right: 6px;
      padding-left: 6px;
      color: var(--abb-theme-neutralSecondary);
    }
    .ms-Button:hover, div[aria-expanded="true"] button.ms-Button {
        color: #fff;
        background-color: var(--abb-red);
        transition: all ease-in-out 0.3s;
        .ms-Button-menuIcon {
          color: #fff;
        }
    }
  }
}

.wrapperBtn {
  position: relative;
  width: 100%;
  max-width: 150px;
  box-sizing: border-box;
  cursor: pointer;
  @include splitButton();
  > div {
    width: 100%;
}
  .btnAction {
    display: flex;
    height: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid var(--abb-red);
    padding: 8px 16px;
    background-color: #fff;
    font-size: 14px;
    border-radius: 2px;
    &:hover {
      color: #fff;
      background-color: var(--abb-red);
      transition: all ease-in-out 0.3s;
    }
    &.isActive {
      color: #fff;
      background-color: var(--abb-red);
      i {
        color: #fff;
        transform: rotate(180deg);
        transition: all ease-in-out 0.3s;
      }
    }
    i {
      position: absolute;
      right: 15px;
      transition: all ease-in 0.3s;
    }
  }
  .calloutMain {
    position: relative;
    display: none;
    width: 100%;
    border: 1px solid var(--abb-grey-4);
    background-color: #fff;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    &.isActive {
      display: block;
    }
    ul {
      margin: 0px;
      padding: 0px;
      padding: 5px 16px;
      list-style-type: none;
      line-height: 2.5em;
      li {
        position: relative;
        box-sizing: border-box;
        font-size: var(--abb-default-font-size);
        font-weight: 400;
        &:hover {
          color: var(--abb-red);
          transition: all ease-in 0.3s;
        }
        i {
          margin-right: 5px;
        }
      }
    }
  }
  >div> div > span > span {
    background-color: var(--abb-red);
  }
  .button {
    width: 100%;
    :global {
      .ms-Button-label {
        font-weight: 400;
      }
    }
  }
  .splitButton {
    border-right-color: transparent;
  }
}

