.sticky {
    position: sticky;
    display: flex;
    top: 48px;
    justify-content: space-between;
    border-bottom: 4px solid var(--palette-primary-main);
    background-color: #fff;
    z-index: 1000000;
}

.close{
    margin-top: 40px;
    margin-right: 20px;
}