@mixin gradient-font-color($color1, $color2) {
  background: linear-gradient( to right, $color1, $color2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin threeD-letters($color: #ff000f) {
  color:  $color;
  text-shadow: 
  -0.0075em 0.0075em 0 mix(#fff, $color, 94%),
  0.005em 0.005em 0 mix(#fff, $color, 60%),
  0.01em 0.01em 0 mix(#fff, $color, 62%), 
  0.015em 0.015em mix(#fff, $color, 64%), 
  0.02em 0.02em 0 mix(#fff, $color, 66%), 
  0.025em 0.025em 0 mix(#fff, $color, 68%),
  0.03em 0.03em 0 mix(#fff, $color, 70%),
  0.035em 0.035em 0 mix(#fff, $color, 72%);
}