.touchBtn + label {
  position: absolute;
  display: flex;
  right: 15px;
  bottom: -29px;
  padding: 7px 15px;
  background: white;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 20%);
  font-size: var(--abb-default-font-size);
  cursor: pointer;
  z-index: 1000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  gap: 5px;
}

.touchBtn:checked + label:after {
  content: 'Close unit selection';
}

.touchBtn + label:after {
  content: 'Select unit';
}

.slide {
  clear: both;
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.4s ease;
}

.box {
  width: 60%;
}

.touchBtn {
  position: absolute;
  height: 0px;
  opacity: 0;
}

.touchBtn:checked ~ .slide {
  max-height: 360px;
}
