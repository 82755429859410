.inputWrapper {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  flex-grow: 1;
}

.regularOwner {
  :global .ms-BasePicker-text {
    border-color: rgb(96, 94, 92);
    border-width: 1px;
    &::after {
      border-color: rgb(96, 94, 92);
      border-width: 1px;
    }
  }
  > div[class*='screenReaderText'] {
    display: none;
  }
}

.regular {
  flex-grow: 1;
  @extend .regularOwner;
}

.error {
  :global .ms-BasePicker-text {
    border-color: rgb(255, 0, 15);
    &::after {
      border-color: rgb(255, 0, 15);
    }
  }
}

.saveAllBtn {
  display: flex;
  margin: 15px 0;
  gap: 15px;
}
