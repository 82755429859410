.middleTopBarWrapper {
  position: relative;
  margin-bottom: 5px;
  border-bottom: 1px solid var(--abb-grey-4);
  padding: 10px 0;
  z-index: 999999;
}
.middleTopBarMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 100px;
}
.leftWrapper {
  display: flex;
  align-items: center;
}
.innerWrapper {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 30px;
  list-style: none;
  & > li {
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    &:hover {
      color: var(--abb-red);
    }
    > a {
      display: flex;
      padding: 8px 0;
      color: inherit;
      font-size: var(--abb-default-font-size);
      text-decoration: none;
    }
    :global a.active {
      padding-bottom: 5px;
      border-bottom-style: solid;
      border-bottom-width: 3px;
      border-bottom-color: var(--abb-red);
    }
  }
}

.viewsPicker {
  margin: 0 10px;
}
