.wrapperMin {
    >div{
        flex-grow: 1;
        border-radius: 20px;
        >span{
            border-radius: 20px;
        }
        &::after{
            display: none;
        }
    }
}
.wrapper{
    display: inline-block;
    height: 32px;
    border-radius: 20px;
    @extend .wrapperMin;
}

.button {
    border-radius: 20px;
    & + *[class*="ms-Button"] {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
}