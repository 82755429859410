.tilesWrapper {
  display: flex;
  gap: 15px;
  margin: 50px 0;

  .link {
    cursor: pointer;
  }
  .tile {
    padding: 18px;
    min-width: 150px;
    max-width: 250px;
    background-color: var(--abb-grey-6);
    border: 1px solid var(--abb-grey-5);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    transition: all ease-in-out 0.3s;
    font-size: 16px;
    &:hover {
      transition: all ease-in-out 0.3s;
      color: var(--abb-red);
    }
    .left {
      justify-content: center;
      align-items: center;
      display: flex;
      padding-right: 7px;
      i {
        font-size: 26px;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &Title {
      font-family: 'ABBvoice Bd';
      margin-bottom: 10px;
      text-align: center;
    }
  }
}
