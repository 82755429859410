@import '../../../../assets/styles/mixins.scss';
.wrapper {
  text-align: center;
  margin: auto;
}

.notFoundNumbers {
  font-size: 12em;
  font-family: 'ABBvoice Bd';
  @include gradient-font-color(#000, var(--abb-red));
  &Red{ 
    color: var(--abb-red);
    font-family: 'ABBvoice Bd';
  }
}
.notFoundText {
  padding: 30px;
  font-size: 34px;
  font-family: 'ABBvoice Lt';
  margin-bottom: 35px;
}
.notFoundBtn {
  a {
    font-family: 'ABBvoice Lt';
    text-decoration: none;
    background-color: var(--abb-red);
    color: var(--abb-white);
    padding: 25px 50px;
    font-size: 18px;
    border-radius: 55px;
  }
}