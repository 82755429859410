img.home {
  width: 18px;
  cursor: pointer;
}

.shimmer {
  height: 28px;
  margin-bottom: 5px;
}
.breadcrumbsWrapper {
  display: flex;
  margin-bottom: 5px;
  font-size: 12px;
  ul.breadcrumbs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: flex;
      color: var(--abb-grey-9);
      line-height: 2em;
      a {
        color: var(--abb-grey-9);
        cursor: pointer;
        text-decoration: none;
        transition: all ease-in-out 0.3s;
        &:hover {
          color: var(--abb-red);
          transition: all ease-in-out 0.3s;
        }
      }
      &::before {
        margin: 0 10px;
        content: '\203A';
      }
      &:first-of-type::before{
        display: none;
      }
    }
  }
}
