@import '../../../assets/styles.scss';

.container {
    display: flex;
    min-width: 80vw;
    align-items: stretch;
    flex-flow: column nowrap;
}

.header {
    display: flex;
    align-items: center;
    border-top: 4px solid var(--palette-primary-main);
    padding: 12px 12px 14px 24px;
    color: var(--palette-grey-9);
    flex: 1 1 auto;
    font-weight: 600;
}
.heading {
  margin-right: 20px;
}

.close {
    margin-top: 4px;
    margin-right: 2px;
    margin-left: auto;
    color: var(--palette-primary-main);
    &:hover{
      color: var(--palette-grey-1);
    }
}

.content {
    padding: 0 24px 14px 24px;
}