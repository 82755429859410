.paddingBottom {
  padding-bottom: 10px;
}

.copyButton {
  border: 1px solid var(--abb-red);
  color: var(--abb-theme-neutralSecondary);
  padding-left: 6px;
  width: 100%;

}

.buttonsContainer {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 20px;
}

.buttonPaddingRight {
  padding-right: 10px;
}
