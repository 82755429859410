.thStyle {
  padding-left: 15px; 
  padding-right: 15px;
  padding-bottom: 7px;
  
}

.tdStyle, .pStyle {
  color: var(--abb-red);
  text-align: left;
  padding-right: 15px;
  padding-left: 15px; 
  font-size: 14px;
}



.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
  font-size: 17px;
  margin-top: 15px;
}