.place {
  border-right: 1px solid var(--abb-grey-4);
  padding: 0 5px;
  font-weight: bold;
  &.last {
    margin-right: 20px;
  }
}

.homeIcon {
  width: 15px;
}
