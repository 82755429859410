.columnHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  span {
    width: 100%;
  }
}

.addNewButton {
  margin-bottom: 20px;
}
.detailsList {
  max-width: 100%;
  position: relative;
  overflow-y: auto;
  min-height: 5vh;
  max-height: 65vh;
  :global .ms-DetailsRow-cell {
    line-height: 27px;
  }
  :global .ms-DetailsList-headerWrapper {
    position: sticky;
    z-index: 200;
    top: 0;
  }
}

.frozenUnitFiles {
  :global .ms-DetailsRow-cell:nth-child(2) {
    position: sticky;
    inset: 0;
    left: 0px;
    background-color: white;
    z-index: 100;
  }

  :global .ms-DetailsRow-cell:nth-child(3) {
    position: sticky;
    inset: 0;
    left: 125px;
    background-color: white;
    z-index: 100;
  }

  :global .ms-DetailsRow-cell:nth-child(4) {
    position: sticky;
    inset: 0;
    left: 470px;
    background-color: white;
    z-index: 100;
  }

  :global .ms-DetailsHeader-cell:nth-child(3) {
    position: sticky;
    left: 0px;
    background-color: white;
    z-index: 100;
  }

  :global .ms-DetailsHeader-cell:nth-child(4) {
    position: sticky;
    left: 125px;
    background-color: white;
    z-index: 100;
  }

  :global .ms-DetailsHeader-cell:nth-child(6) {
    position: sticky;
    left: 470px;
    background-color: white;
    z-index: 100;
  }
}

.frozen {
  :global .ms-DetailsRow-cell:nth-child(1) {
    position: sticky;
    inset: 0;
    left: 0px;
    background-color: white;
    z-index: 100;
  }

  :global .ms-DetailsRow-cell:nth-child(2) {
    position: sticky;
    inset: 0;
    left: 125px;
    background-color: white;
    z-index: 100;
  }

  :global .ms-DetailsRow-cell:nth-child(3) {
    position: sticky;
    inset: 0;
    left: 470px;
    background-color: white;
    z-index: 100;
  }

  :global .ms-DetailsHeader-cell:nth-child(1) {
    position: sticky;
    left: 0px;
    background-color: white;
    z-index: 100;
  }

  :global .ms-DetailsHeader-cell:nth-child(2) {
    position: sticky;
    left: 125px;
    background-color: white;
    z-index: 100;
  }

  :global .ms-DetailsHeader-cell:nth-child(4) {
    position: sticky;
    left: 470px;
    background-color: white;
    z-index: 100;
  }
}

.addButton {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}
.copyIcon {
  margin-right: 5px;
  cursor: pointer;
}

.spinner {
  min-width: 180px;
  display: flex;
  justify-content: center;
}
