@import '../../../../assets/styles/mixins.scss';
.wrapper {
  text-align: center;
  margin: auto;
}

.accessDeniedNumbers {
  font-size: 12em;
  font-family: 'ABBvoice Bd';
  @include gradient-font-color(#000, var(--abb-red));
  &Red{ 
    color: var(--abb-red);
    font-family: 'ABBvoice Bd';
  }
}
.accessDeniedHeader {
  padding: 30px;
  font-size: 34px;
  font-family: 'ABBvoice Lt';
}
.accessDeniedText {
  font-size: 23px;
  font-family: 'ABBvoice Lt';
}
.accessDeniedBtn {
  margin-top: 68px;
  a {
    font-family: 'ABBvoice Lt';
    text-decoration: none;
    background-color: var(--abb-red);
    color: var(--abb-white);
    padding: 25px 50px;
    font-size: 18px;
    border-radius: 55px;
   
  }
}