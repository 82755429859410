.footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 20px 0;
  .buttonsAction button {
    margin-right: 15px;
  }
}

.inputWrapper {
  display: flex;
  gap: 20px;
}

.tagPicker {
  flex-grow: 1;
}

.button {
  margin-top: 20px;
  margin-right: 20px;
}
.wrapperForRemoved {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.removedWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
  gap: 10px;
}

.editIcon {
  padding-left: 10px;
  &:hover {
    background: rgb(225, 223, 221);
    cursor: pointer;
  }
  &:focus {
    background: rgb(255, 0, 15);
    color: white;
  }
}
