.lastEditor {
  &Wrapper p {
    font-size: 14px;
  }
  &Inner {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 10px 0;
  }
}

.update {
  display: flex;
  height: 32px;
  align-items: center;
  padding: 0 16px;
  border: 1px solid;
  border-color: var(--abb-grey-4);
  border-radius: 20px;
  gap: 5px;
}
