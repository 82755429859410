.optionsWrapper {
  display: flex;
  min-width: 199px;
  height: 100%;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  line-height: 20px;
  &:hover {
    background-color: #f3f2f1;
    cursor: pointer;
  }
  .option {
    display: flex;
    min-width: 0px;
    width: 100%;
    max-width: 100%;
    min-height: 36px;
    align-items: center;
    margin: 1px;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }
}
.starIcons {
  position: relative;
  margin-right: 15px;
  color: #ffbf00;
  transition: all linear 0.3s;
  .activeStar {
    display: block;
    opacity: 1;
  }
  .inactiveStar {
    display: none;
    opacity: 0;
  }
}
.trash{
  position: relative;
  margin-right: 10px;
  margin-left: 5px;
}
