@import 'styles/colors.scss';
@import 'styles/font.scss';
@import './styles/mixins.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'ABBvoice', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  padding: 75px 25px 35px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
}
main.isLoading {
  justify-content: center;
  padding: 0;
}

h1 {
  margin: 0;
  color: var(--page-title-color);
  font-size: 64px;
  font-family: 'ABBvoice';
  line-height: 68px;
}
h2 {
  margin: 0;
  color: var(--page-title-color);
  font-size: 48px;
  font-family: 'ABBvoice';
  line-height: 52px;
}
h3 {
  margin: 0;
  font-size: 32px;
  font-family: 'ABBvoice';
  line-height: 1.5;
}
h4 {
  margin: 0;
  font-size: 28px;
  font-family: 'ABBvoice';
}
h5 {
  margin: 0;
  color: var(--palette-black-2);
  font-size: 24px;
  font-family: 'ABBvoice';
  line-height: 28px;
}
h6 {
  margin: 0;
  font-size: 20px;
  font-family: 'ABBvoice';
}
p {
  margin: 0;
  color: var(--palette-black-2);
  font-size: 16px;
  font-family: 'ABBvoice';
}
span,
button,
a,
ul,
ol,
table th,
table td,
input,
label {
  font-family: 'ABBvoice';
}

.isHidden {
  display: none;
}
.noPaddingTop {
  flex-grow: 1;
  padding-top: 0;
}

:global .sun-editor-editable{
  color:rgb(15,15,15);
  font-size:16px;
  font-family:ABBvoice;
}

:global .sun-editor {
  padding: 0;
}

:global .sun-editor.no-border {
  border: none;
  color:rgb(15,15,15);
  background-color: transparent;
  font-size:16px;
  font-family:ABBvoice;
}


:global .sun-editor.can-copy{
  user-select: auto;
}
