footer.btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}

.manageAuditable {
  &Note {
    margin-top: 20px;
    color: var(--abb-red);
    padding: 15px;
    border: 1px solid var(--abb-red-2);
    font-weight: 600;
    i {
      position: relative;
      top: 2px;
      padding-right: 5px;
    }
  }
  &Decision {
    margin: 20px 0;
    p {
      display: inline;
    }
  }
  &Content {
    max-height: 40vh;
  }
}

