$colorPrimary: var(--abb-red);
$colorBorder: var(--abb-grey-4);

.wrapper {
  display: flex;
  max-width: 100%;
  justify-content: center;
}

.pagination {
  display: flex;
  margin: 35px 0;
  padding-left: 0;
  overflow: auto;
  border-radius: 2px;
}
.pageItem {
  display: inline;
  &:first-child {
    .pageLink {
      margin-left: 0;
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
    }
  }
  &:last-child {
    .pageLink {
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
    }
  }
}
.pageItem.active {
  .pageLink {
    color: #fff;
    background-color: $colorPrimary;
    cursor: default;
    z-index: 2;
    border-color: $colorPrimary;
    &:focus {
      color: #fff;
      background-color: $colorPrimary;
      cursor: default;
      z-index: 2;
      border-color: $colorPrimary;
    }
    &:hover {
      color: #fff;
      background-color: $colorPrimary;
      cursor: default;
      z-index: 2;
      border-color: $colorPrimary;
    }
  }
}
.pageItem.disabled {
  .pageLink {
    color: $colorBorder;
    background-color: #fff;
    cursor: not-allowed;
    pointer-events: none;
    border-color: $colorBorder;
    &:focus {
      color: $colorBorder;
      background-color: #fff;
      cursor: not-allowed;
      pointer-events: none;
      border-color: $colorBorder;
    }
    &:hover {
      color: $colorBorder;
      background-color: #fff;
      cursor: not-allowed;
      pointer-events: none;
      border-color: $colorBorder;
    }
  }
}
.pageLink {
  position: relative;
  float: left;
  margin-left: -1px;
  border: 1px solid $colorBorder;
  padding: 0.5rem 0.75rem;
  color: var(--abb-grey-1);
  background-color: #fff;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  &:focus {
    color: $colorPrimary;
    background-color: var(--abb-grey-6);
    border-color: $colorBorder;
  }
  &:hover {
    color: $colorPrimary;
    background-color: var(--abb-grey-6);
    border-color: $colorBorder;
  }
}
