.link {
  text-decoration: none;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  .linkTitle {
    transition: ease-in-out 0.3s;
    &:hover {
      color: var(--abb-red);
    }
  }
}
.linkTitle {
  font-family: 'ABBvoice';
  &.nonProcess {
    color: var(--abb-red);
    cursor: default;
  }
}
