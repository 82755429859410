@mixin splitButton() {
  :global {
    .ms-Button {
      border: 1px solid var(--abb-red);
      padding-right: 6px;
      padding-left: 6px;
      color: var(--abb-theme-neutralSecondary);
    }
    .ms-Button:hover, div[aria-expanded="true"] button.ms-Button {
        color: #fff;
        background-color: var(--abb-red);
        transition: all ease-in-out 0.3s;
        .ms-Button-menuIcon {
          color: #fff;
        }
    }
  }
}

.wrapperBtn {
  position: relative;
  width: 100%;
  max-width: 150px;
  box-sizing: border-box;
  cursor: pointer;
  @include splitButton();
  > div {
    width: 100%;
}
 
  
  .button {
    width: 100%;
    :global {
      .ms-Button-label {
        font-weight: 400;
      }
    }
  }
}

