.container {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
  
  .textField, .dropdown {
    width: 23%;
  }
  .description {
    margin-top: 20px;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .fieldsContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  