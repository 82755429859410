.arrow {
    display: flex;
    flex-grow: 1;
    transition: all ease-in-out 0.3s;
    text-decoration: none;
    svg, path, .body{
        transition: inherit;
    }
    svg{
        flex-basis: 10px;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.defaultBody {
    display: flex;
    max-width: calc(100% - 20px);
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding: 0.7em 1em;
    color: var(--abb-grey-1);
    overflow: hidden;
}

.primary {
    .body{
        background-color: var(--abb-red);
    }
    path{
        fill: var(--abb-red);
    }
}

.default {
    .body{
        background-color: var(--abb-grey-4);
    }
    &.link:hover {
        .body{
            background-color: var(--abb-grey-10);
        }
    }
    path{
        fill: var(--abb-grey-4);
    }
    &.link:hover {
        path {
            fill: var(--abb-grey-10);
        }
    }
}
.noLink {
    font-family: "ABBvoice Lt";
}