.homePage {
  line-height: 1.5;
  p {
    margin: 10px 0;
  }
  .redBorderDecorator {
    width: 40px;
    height: 5px;
    background-color: var(--abb-red);
  }
}
