@font-face {
  font-family: "ABBvoice";
  src: url("../fonts/ABBvoice_W_Rg.woff2") format("woff2"), url("../fonts/ABBvoice_W_Rg.woff")
      format("woff");
}
@font-face {
  font-family: "ABBvoice Bd";
  src: url("../fonts/ABBvoice_W_Bd.woff2") format("woff2"), url("../fonts/ABBvoice_W_Bd.woff")
      format("woff");
}
@font-face {
  font-family: "ABBvoice Lt";
  src: url("../fonts/ABBvoice_W_Lt.woff2") format("woff2"), url("../fonts/ABBvoice_W_Lt.woff")
      format("woff");
}
@font-face {
  font-family: "ABBvoice Md";
  src: url("../fonts/ABBvoice_W_Md.woff2") format("woff2"), url("../fonts/ABBvoice_W_Md.woff")
      format("woff");
}