.addFieldContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: baseline;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.label {
  width: 20%;
  margin-right: 20px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;

}


.wrapperForRemoved {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.removedWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
  gap: 10px;
}



